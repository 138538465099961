@font-face {
  font-family: 'go-light';
  src: url('./../font/GOTHAMLIGHT/GothamLight.woff') format('woff'),
    url('./../font/GOTHAMLIGHT/GothamLight.ttf') format('truetype');
}

html {
  background-color: $viewport-background-color;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html,
body {
  font-size: 0.93rem !important;
  height: 100%;
}

body {
  margin: 0;
}